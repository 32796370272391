import { Button, DateFormat, enhancedFetch, JourneyAppContext, useFetch, useSocket } from 'journey-ui'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AppContext } from '../../contexts/AppContext'

export const BankAccountBanner = () => {
  const { brand, user, brandHashId, bankErrorData, setBankErrorData } = useContext(AppContext)
  const { setAlert } = useContext(JourneyAppContext)

  const { has_error, bank_account_url, loaded, brand_hash_id: lastBrandHashId } = bankErrorData

  useEffect(() => {
    if ((loaded && lastBrandHashId === brandHashId) || !brandHashId) {
      return
    }

    enhancedFetch<BankErrorData>(`/api/brand/${brandHashId}/bank-account-errors`)
      .then((data) => {
        setBankErrorData({
          ...data,
          brand_hash_id: brandHashId,
          loaded: true,
        })
      })
      .catch(console.error)
  }, [loaded, brandHashId, lastBrandHashId, setBankErrorData])

  useSocket({
    channelId: user && brand?.hash_id ? `brand.${brand.hash_id}` : '',
    type: 'private',
    eventId: '.bank-account.updated',
    onMessage: (message) => {
      setBankErrorData({
        ...bankErrorData,
        loaded: false,
      })
    },
  })

  const hasOwnerAccess = brand?.is_owner
  const router = useRouter()
  const fixAccountClick = () => {
    if (!hasOwnerAccess) {
      setAlert('You are not logged in as the owner so you do not have permission to fix the bank account, please contact the owner and have them click this button.')
    } else {
      router.push(bank_account_url)
    }
  }

  if (!has_error) {
    return null
  }

  return (
    <div className="w-full p-2 bg-red-500 flex flex-col justify-center text-white font-bold items-center space-y-2">
      <span>Your bank account needs updating, please press the button below to avoid disruption.</span>
      <Button className="h-7 min-h-0 primary" onClick={fixAccountClick}>
        Fix Account
      </Button>
    </div>
  )
}
