import { Avatar, Badge, Button, CompProps, ElementProps, Icon } from 'journey-ui'
import React, { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { ChartBar, ChatsCircle, GearSix, Printer, ShoppingCart, SignIn } from 'phosphor-react'
import { AppContext } from '../../contexts/AppContext'

interface SideButtonProps extends CompProps<'button'> {
  activePattern: string
}

const SideButton: React.FC<SideButtonProps> = (props) => {
  const router = useRouter()

  const isActive = router.asPath.includes(props.activePattern)

  const onClickHandle = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    router.push(props.href)
  }, [props.href, router])

  return (
    <Button
      className={classNames('w-full flex-grow border-none max-h-24 rounded-none justify-center p-0 m-0', { 'text-primary': isActive, 'text-white': !isActive })}
      {...props}
      onClick={onClickHandle}
    >
      {props.children}
    </Button>
  )
}

const SideButtonIcon = (props) => <Icon className="w-8 h-8" {...props} />

interface Props extends ElementProps<'div'> {

}

export const AppSidePanel: React.FC<Props> = (props) => {
  const { user, brandHashId, unreadChatCount, hasOwnerAccess } = useContext(AppContext)

  return (
    <>
      <div className="flex-shrink-0 w-24 bg-app-bar z-20 relative hidden sm:flex">
        <div className={classNames(props.className, 'h-full w-24 fixed inset-0 justify-between items-center flex-shrink-0 flex-col hidden sm:flex')}>
          <div className="w-full flex flex-col flex-grow">
            {brandHashId ? (
              <>
                <SideButton href={`/brand/${brandHashId}/orders`} activePattern="/order">
                  <SideButtonIcon src={ShoppingCart} />
                </SideButton>
                {hasOwnerAccess && (
                <SideButton href={`/brand/${brandHashId}/chats`} activePattern="/chats">
                  <SideButtonIcon src={ChatsCircle} />
                  {' '}
                  {unreadChatCount ? <Badge className="danger size-sm absolute top-5 right-5 font-bold">{unreadChatCount}</Badge> : null}
                </SideButton>
                )}
                <SideButton href={`/brand/${brandHashId}/printers`} activePattern="/printers">
                  <SideButtonIcon src={Printer} />
                </SideButton>
                {hasOwnerAccess && (
                <SideButton href={`/brand/${brandHashId}/report`} activePattern="/report">
                  <SideButtonIcon src={ChartBar} />
                </SideButton>
                )}
                <SideButton href={`/brand/${brandHashId}/settings`} activePattern="/settings">
                  <SideButtonIcon src={GearSix} />
                </SideButton>

              </>
            ) : (
              <SideButton href="/login" activePattern="/login">
                <SideButtonIcon src={SignIn} />
              </SideButton>
            )}

          </div>
          <div className="my-4">
            {user
              ? <Avatar src={user.avatar} href={`/brand/${brandHashId}/settings`} />
              : null}
          </div>
        </div>
      </div>
    </>
  )
}
