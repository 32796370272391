import { useContext, useEffect, useState } from 'react'
import { Button } from 'journey-ui'
import { Warning } from 'phosphor-react'
import { AppContext } from '../../contexts/AppContext'
import { isTablet } from '../../helpers'

export const SystemMessageBanner = () => {
  const { brand } = useContext(AppContext)

  if (!brand?.pos_system_message) {
    return null
  }

  return (
    <div className="bg-yellow-600 w-full space-x-2 flex-shrink-0 flex justify-center items-center text-white p-3">
      <Warning size={22} />
      <span>
        {`System Message: ${brand.pos_system_message}`}
      </span>
    </div>
  )
}
