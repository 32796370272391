import { Badge, Button, ElementProps, Footer, Icon, Journey } from 'journey-ui'
import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { ChartBar, ChatsCircle, GearSix, ShoppingCart, SignIn } from 'phosphor-react'
import { AppContext } from '../../contexts/AppContext'

interface Props extends ElementProps<'div'> {

}

const FooterButton = (props) => {
  const { children, activePattern, className } = props

  const router = useRouter()

  const isActive = router.asPath.includes(activePattern)

  return (
    <Button
      {...props}
      className={classNames(
        'flex-grow flex-col hover:bg-white hover:text-primary space-y-1 rounded-none border-none',
        { 'text-primary': isActive, 'text-gray-500': !isActive },
        className,
      )}
    >
      {children}
    </Button>
  )
}

const FooterButtonText = Journey.div('text-xs')

export const AppFooter: React.FC<Props> = (props) => {
  const { brand, hasOwnerAccess, user, unreadChatCount } = useContext(AppContext)

  return (
    <Footer className={classNames(props.className, 'justify-center p-0 md:hidden fixed w-full left-0 bottom-0 z-20')}>
      <div className="max-w-[1080px] flex w-full">
        {user ? (
          <>
            <FooterButton href={`/brand/${brand?.hash_id}/orders`} activePattern="/order">
              <Icon src={ShoppingCart} />
              <FooterButtonText>Orders</FooterButtonText>
            </FooterButton>
            {hasOwnerAccess && (
            <FooterButton href={`/brand/${brand?.hash_id}/chats`} activePattern="/chat" className="overflow-visible">
              <div className="relative">
                <Icon src={ChatsCircle} />
                {unreadChatCount ? <Badge className="danger size-sm absolute top-[-5px] right-[-10px] font-bold">{unreadChatCount}</Badge> : null}
              </div>
              <FooterButtonText>Chats</FooterButtonText>
            </FooterButton>
            )}
            {hasOwnerAccess && (
            <FooterButton href={`/brand/${brand?.hash_id}/report`} activePattern="/report">
              <Icon src={ChartBar} />
              <FooterButtonText>Reports</FooterButtonText>
            </FooterButton>
            )}
            <FooterButton href={`/brand/${brand?.hash_id}/settings`} activePattern="/settings">
              <Icon src={GearSix} />
              <FooterButtonText>Settings</FooterButtonText>
            </FooterButton>
          </>
        ) : (
          <>
            <FooterButton href="/login" activePattern="/login">
              <Icon src={SignIn} />
              <FooterButtonText>Login</FooterButtonText>
            </FooterButton>
          </>
        )}
      </div>
    </Footer>
  )
}
