import { Button, ElementProps, Page } from 'journey-ui'
import Head from 'next/head'
import React, { useContext } from 'react'
import { AppSidePanel } from './AppSidePanel'
import { AppFooter } from './AppFooter'
import { AppContext } from '../../contexts/AppContext'
import { NoPrinterBanner } from './NoPrinterBanner'
import { OfflineStatusBanner } from './OfflineStatusBanner'
import { BankAccountBanner } from './BankAccountBanner'
import { SystemMessageBanner } from './SystemMessageBanner'

interface Props extends ElementProps<'div'> {
  title: string
}

export const AppPage: React.FC<Props> = (props) => {
  const { title, children } = props

  const { fatalError, user } = useContext(AppContext)

  return (
    <Page title={title} {...props}>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />

      </Head>

      <AppSidePanel className="hidden sm:block" />

      <div className="content-container">
        {fatalError ? (
          <div className="bg-red-500 w-full space-y-2 text-center text-white p-3">
            <span>{fatalError}</span>
            <div className="flex justify-center">
              <Button className="flex-grow-0" onClick={() => window.location.reload(true)}>
                Refresh
              </Button>
            </div>
          </div>
        ) : null}

        <SystemMessageBanner />
        <NoPrinterBanner />
        <OfflineStatusBanner />
        <BankAccountBanner />

        {children}
      </div>
      <AppFooter className="block sm:hidden" />
    </Page>
  )
}
