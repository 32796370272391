import { useContext, useEffect, useState } from 'react'
import { Button } from 'journey-ui'
import { AppContext } from '../../contexts/AppContext'
import { isTablet } from '../../helpers'

export const NoPrinterBanner = () => {
  const { brand, printers, printerPaginator } = useContext(AppContext)

  const [shouldRender, setShouldRender] = useState(false)

  const hasPrintersFetched = printerPaginator?.status === 'completed'

  useEffect(() => {
    /* If no brand is loaded, already dismissed, already has a printer, or isn't a tablet, don't show this banner. */
    if (!brand || window.localStorage.getItem('printer-banner-checked') || !isTablet()) {
      setShouldRender(false)
    } else {
      if (!hasPrintersFetched) {
        return
      }

      setShouldRender(printers.length === 0)
    }
  }, [brand, hasPrintersFetched, printers])

  const onDismissClick = () => {
    window.localStorage.setItem('printer-banner-checked', '1')
    setShouldRender(false)
  }

  if (!shouldRender) {
    return null
  }

  return (
    <div className="bg-yellow-600 w-full flex-shrink-0 flex justify-between items-center text-white p-3">
      <span>You don't have any printers added.</span>
      <div className="flex space-x-2 items-center">
        <Button href={`/brand/${brand?.hash_id}/printers`}>
          Add Printer
        </Button>
        <Button onClick={onDismissClick}>
          Dismiss
        </Button>
      </div>
    </div>
  )
}
