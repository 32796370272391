import { DateFormat } from 'journey-ui'
import * as React from 'react'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

export const OfflineStatusBanner = () => {
  const { brand } = useContext(AppContext)

  return (
    <>
      {brand?.reset_online_at ? (
        <div className="w-full p-2 bg-red-500 flex justify-center text-white font-bold items-center space-x-1">
          <DateFormat value={brand.reset_online_at} format="M/dd/yyyy h:mma" prefix="Your online ordering is off until " />
        </div>
      ) : null}
      {brand?.reset_delivery_at ? (
        <div className="w-full p-2 bg-red-500 flex justify-center text-white font-bold items-center space-x-1">
          <DateFormat value={brand.reset_delivery_at} format="M/dd/yyyy h:mma" prefix="Your delivery is off until " />
        </div>
      ) : null}
      {brand?.vacation ? (
        <div className="w-full p-2 bg-red-500 flex justify-center text-white font-bold items-center space-x-1">
          <span>Your online ordering is off for vacation from</span>
          <DateFormat value={brand.vacation.start_date} format="M/dd/yyyy" />
          <span>to</span>
          <DateFormat value={brand.vacation.end_date} format="M/dd/yyyy" />
        </div>
      ) : null}
    </>
  )
}
